import {
  LIVE_CONFIGURATION,
  POST_CONFIGURATION,
  PUSH_CONFIGURATION
} from "@/constants/routes";
import {
  loginLinkRoutes,
  signupLinkRoutes
} from "@/constants/signupLinkRoutes";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Item } from "react-stately";

import { Button, IconName, Logo, Text } from "@leeloo/core";

import { Icon } from "@/components/icon/Icon";
import * as styles from "@/components/navigation-bar/navigation-bar.css";

import { LoginLink } from "@/features/authentication/presentation/containers/login-link/LoginLink";
import { SignupLink } from "@/features/authentication/presentation/containers/signup-link/SignupLink";
import Menu from "@/features/profile/presentation/containers/menu/Menu";

type MenuItem = {
  label: string;
  icon: IconName;
  href?: string;
};

interface NavigationBarProps {
  menuProfile?: MenuItem[];
  withMobileNavBar?: boolean;
  withProfileMenu?: boolean;
  logged?: boolean;
}

const NavigationBar = ({
  withProfileMenu = true,
  withMobileNavBar,
  logged,
  ...rest
}: NavigationBarProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const path = window.location.pathname;

  const links: {
    name: string;
    href: string;
    icon:
      | "tools"
      | "statistics"
      | "message"
      | "notification"
      | "profile"
      | "settings";
  }[] = [
    {
      name: t("navbar_tools"),
      href: `${window.location.origin}/app/account/tools`,
      icon: "tools"
    },
    {
      name: t("navbar_statistics"),
      href: `${window.location.origin}/app/incomes`,
      icon: "statistics"
    },
    {
      name: t("navbar_messages"),
      href: `${window.location.origin}/app/myms`,
      icon: "message"
    },
    {
      name: t("navbar_profile"),
      href: `${window.location.origin}/app/profile`,
      icon: "profile"
    },
    {
      name: t("notifications_title"),
      href: `${window.location.origin}/app/notifications`,
      icon: "notification"
    },
    {
      name: t("navbar_settings"),
      href: `${window.location.origin}/app/my-account`,
      icon: "settings"
    }
  ];
  interface MenuItem {
    label: string;
    icon: IconName;
    href: string;
  }

  const menu: MenuItem[] = [
    {
      label: "Post",
      icon: "post",
      href: POST_CONFIGURATION
    },
    {
      label: "Push",
      icon: "push",
      href: PUSH_CONFIGURATION
    },
    {
      label: "Live",
      icon: "live",
      href: LIVE_CONFIGURATION
    }
  ];

  return (
    <nav
      className={styles.navigationBar({
        withMobileNavBar: !!withMobileNavBar,
        logged
      })}
      data-testid="navigation-bar"
      {...rest}
    >
      <ul className={styles.navigationList}>
        <Logo data-testid="logo" className={styles.logo} />
        {logged && (
          <div className={styles.navigationLinks}>
            {links?.map((link, index) => (
              <li key={index}>
                <a
                  className={styles.linkItem}
                  href={link?.href}
                  rel="noreferrer noopenner"
                  data-testid={link?.icon}
                >
                  <Icon
                    name={link?.icon}
                    size="large"
                    data-testid={`icon-${link?.icon}`}
                  />
                  <Text
                    className={styles.desktopContent}
                    data-testid={link?.name}
                  >
                    {link?.name}
                  </Text>
                </a>
              </li>
            ))}
            {withProfileMenu && (
              <Menu
                className={styles.buttonAction}
                onAction={(key) => navigate(`${key}`)}
                popoverProps={{ withUnderlay: true }}
                buttonComponent={
                  <Button leftIcon="add" className={styles.buttonAction}>
                    {t("create_new_media_button")}
                  </Button>
                }
              >
                {menu?.map((item) => (
                  <Item key={item?.href} textValue={item?.label}>
                    <Text>{item.label}</Text>
                    <Icon name={item.icon} size="large" />
                  </Item>
                ))}
              </Menu>
            )}
          </div>
        )}
        {loginLinkRoutes.includes(path) ? <LoginLink centered /> : null}
        {signupLinkRoutes.includes(path) ? (
          <SignupLink className={styles.signupLink} />
        ) : null}
      </ul>
    </nav>
  );
};

export default NavigationBar;
