import { isImage, isVideo } from "./fileFormat";

export const getMediaResolution = async (
  file: File,
  minHeight: number,
  minWidth: number
): Promise<{ width: number; height: number }> => {
  const fileUrl = URL.createObjectURL(file);

  return new Promise((resolve, reject) => {
    const checkResolution = (width: number, height: number) => {
      if (width < minWidth || height < minHeight) {
        reject({
          type: "MINIMUM_DIMENSION",
          message: `Minimum resolution is ${minHeight} x ${minWidth}`,
        });
      } else {
        resolve({ width, height });
      }
    };

    if (isImage(file)) {
      const image = new Image();
      image.src = fileUrl;
      image.onload = () => {
        checkResolution(image.naturalWidth, image.naturalHeight);
      };
      image.onerror = () => reject(new Error("Failed to load image"));
    } else if (isVideo(file)) {
      const video = document.createElement("video");
      video.src = fileUrl;

      video.onloadedmetadata = () => {
        resolve({ width: video.videoWidth, height: video.videoHeight });
      };
      video.onerror = () => reject(new Error("Failed to load video"));
    } else {
      reject(new Error("Unsupported file type"));
    }
  });
};
