import React, { useEffect } from "react";

import {
  POST_CONFIGURATION,
  POST_CONFIRMATION,
  POST_MYM_CLOUD,
  POST_MYM_CLOUD_COLLECTION,
  POST_UPDATE,
  POST_UPDATE_CONFIRMATION
} from "@/constants/routes";
import { RouteConfig } from "@/routes/routes";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { Outlet, Route, Routes } from "react-router-dom";

import Collection from "../pages/collection/Collection";
import { uploadPost } from "../pages/setup/formValidation";

const Setup = React.lazy(
  () => import("@/features/post/presentation/pages/setup")
);

const MymCloud = React.lazy(
  () => import("@/features/post/presentation/pages/mym-cloud")
);

const Confirmation = React.lazy(
  () => import("@/features/post/presentation/pages/confirmation")
);

const Update = React.lazy(
  () => import("@/features/post/presentation/pages/update")
);

const UpdateConfirmation = React.lazy(
  () => import("@/features/post/presentation/pages/update-confirmation")
);

const routesConfig: RouteConfig[] = [
  {
    path: POST_CONFIGURATION,
    element: <Setup />,
    isPrivate: true
  },
  {
    path: POST_MYM_CLOUD,
    element: <MymCloud />,
    isPrivate: true
  },
  {
    path: POST_MYM_CLOUD_COLLECTION,
    element: <Collection />,
    isPrivate: true
  },
  {
    path: POST_CONFIRMATION,
    element: <Confirmation />,
    isPrivate: true
  },
  {
    path: POST_UPDATE,
    element: <Update />,
    isPrivate: true
  },
  {
    path: POST_UPDATE_CONFIRMATION,
    element: <UpdateConfirmation />,
    isPrivate: true
  }
];

export interface PostFormProps {
  media: {
    file?: File;
    properties: {
      url: string;
      type: string;
      id?: string;
      size?: number;
      extension?: string;
      preview?: string;
    };
  } | null;
  caption: string;
  isPrivate: boolean;
  isScheduled: boolean;
  scheduledAt: Date;
  playlists: string[];
  thirdPartyConsent: boolean;
}

const FormProviderWrapper = ({ children }: any) => {
  const methods = useForm<PostFormProps>({
    criteriaMode: "all",
    defaultValues: {
      media: null,
      caption: "",
      isPrivate: true,
      isScheduled: false,
      scheduledAt: new Date(),
      playlists: [],

      thirdPartyConsent: false
    },
    mode: "onSubmit",
    resolver: yupResolver(uploadPost)
  });

  useEffect(() => {
    methods.reset({
      media: null,
      caption: "",
      isPrivate: true,
      scheduledAt: new Date(),
      isScheduled: false,
      playlists: [],
      thirdPartyConsent: false
    });
  }, []);

  return <FormProvider {...methods}>{children}</FormProvider>;
};

export const PostRoutes = () => {
  return (
    <FormProviderWrapper>
      <Routes>
        <Route path="/" element={<Outlet />}>
          {routesConfig.map((route, index) => {
            return (
              <Route key={index} path={route.path} element={route.element} />
            );
          })}
        </Route>
      </Routes>
    </FormProviderWrapper>
  );
};

export default PostRoutes;
