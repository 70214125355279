import { Collections } from "@/features/post/domain/models/collection";
import { Media } from "@/features/post/domain/models/media";
import { HttpGateway } from "@/features/post/infrastructure/services/http";

export interface GetCollectionsParams {
  pagination: {
    perPage: number;
    offset?: string;
  };
}
export interface GetCollectionByIdParams {
  id: string;
  pagination: {
    perPage: number;
    offset?: string;
  };
}

export interface GetCollectionsResponse {
  collections: Collections;
  pagination: {
    perPage: number;
    offset?: string;
    itemsTotal: number;
  };
}
export interface GetCollectionByIdResponse {
  name: string;
  medias: Media[];
  pagination: {
    perPage: number;
    offset?: string;
    itemsTotal: number;
  };
}

export const collectionDataSourceImplementation = (gateway: HttpGateway) => ({
  getCollections: async <T>(params: GetCollectionsParams): Promise<T> => {
    try {
      return await gateway.get(
        `/creators/collections${
          params.pagination.offset
            ? `?perPage=${params.pagination.perPage}&offset=${params.pagination.offset}`
            : `?perPage=${params.pagination.perPage}`
        }`
      );
    } catch (e) {
      throw Error("Error while getting collections", {
        cause: e
      });
    }
  },
  getCollectionById: async <T>(params: GetCollectionByIdParams): Promise<T> => {
    try {
      return await gateway.get(
        `/creators/collections/${params.id}${
          params.pagination.offset
            ? `?perPage=${params.pagination?.perPage}&offset=${params.pagination.offset}`
            : `?perPage=${params.pagination?.perPage}`
        }`
      );
    } catch (e) {
      throw Error("Error while getting collections by id", {
        cause: e
      });
    }
  }
});
