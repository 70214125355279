import clsx from "clsx";

import * as styles from "./content.css";

interface ContentProps {
  className?: string;
  sidebar?: React.ReactNode;
  header?: React.ReactNode;
  children: React.ReactNode;
}

export function Content({
  className,
  sidebar,
  header,
  children,
  ...rest
}: ContentProps) {
  return (
    <main
      data-testid="main"
      className={styles.main({ hasSidebar: !!sidebar })}
      {...rest}
    >
      {!!sidebar && sidebar}
      <div className={clsx(styles.content, className)} data-testid="content">
        {!!header && header}
        {children}
      </div>
    </main>
  );
}
