import { mobileNavBarRoutes } from "@/constants/mobileNavBarRoutes";
import clsx from "clsx";
import { useLocation } from "react-router-dom";

import NavigationBar from "@/components/navigation-bar";

import * as styles from "./layout-unauth.css";

interface LayoutProps {
  className?: string;
  children: React.ReactNode;
}

export function LayoutUnauth({ className, children, ...rest }: LayoutProps) {
  const { pathname } = useLocation();

  return (
    <div
      className={clsx(styles.layout, className)}
      data-testid="layout-unauth"
      {...rest}
    >
      <NavigationBar
        withProfileMenu={false}
        withMobileNavBar={mobileNavBarRoutes.includes(pathname)}
        data-testid="layout-navigation-bar"
      />
      {children}
    </div>
  );
}
