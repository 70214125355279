import MediaGridSelectionItem from "@/components/media-grid-selection/media-grid-selection-item";

import * as styles from "./media-grid-selection.css";

type Media = {
  id: string;
  preview: string;
  type: "video" | "image";
  private?: boolean;
  isScheduled?: boolean;
  duration?: number;
  price?: {
    value: number;
    currency: string;
  };
  imageCount?: number;
  videoCount?: number;
};

export interface MediaGridSelectionProps<T> {
  medias?: T[];
  onSelectItem: (media?: T) => void;
  selectedMedias: T[];
  maxItems?: number;
}

export default function MediaGridSelection<T extends Media>({
  medias,
  onSelectItem,
  selectedMedias,
  maxItems,
  ...props
}: MediaGridSelectionProps<T>) {
  return (
    <div className={styles.panel} data-testid="media-grid-panel" {...props}>
      {medias?.map((media: Media, index: number) => (
        <MediaGridSelectionItem
          data-testid="media-grid-selection-item"
          media={media}
          key={index}
          disabled={
            selectedMedias?.length > 0 && maxItems === 1
              ? !selectedMedias.some(
                  (selectedMedia) => selectedMedia.id === media.id
                )
              : false
          }
          checked={!!selectedMedias.find((medium) => medium.id === media.id)}
          onSelectItem={(mediaId: string) => {
            onSelectItem(medias.find((media: Media) => media.id === mediaId));
          }}
        />
      ))}
    </div>
  );
}
