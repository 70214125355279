import React, { useEffect } from "react";

import { type ToastProps, showToast } from "@leeloo/core";

const ErrorContext = React.createContext({});

export { ErrorContext };

type Error = Pick<ToastProps, "description" | "title">;

export interface ErrorContextProps {
  error: Error;
  setError: (error: Error) => void;
}

export const Provider = ({ ...props }) => {
  const [error, setError] = React.useState({} as Error);

  useEffect(() => {
    if (Object.keys(error).length === 0) {
      return;
    }

    showToast({ ...error, variant: "error" });
  }, [error]);

  return <ErrorContext.Provider {...props} value={{ error, setError }} />;
};
