import React from "react";

import { PLAYLIST, PLAYLISTS } from "@/constants/routes";
import { RouteConfig } from "@/routes/routes";

const Playlists = React.lazy(
  () => import("@/features/playlist/presentation/pages/playlists")
);

const Playlist = React.lazy(
  () => import("@/features/playlist/presentation/pages/playlist")
);

export const routes: RouteConfig[] = [
  {
    path: PLAYLISTS,
    element: <Playlists />,
    isPrivate: true,
  },
  {
    path: PLAYLIST,
    element: <Playlist />,
    isPrivate: true,
  },
];
