import { environment } from "@/environments/environment";

// AUTHENTICATION
export const LOGIN = "/login";
export const LOGIN_MFA = "/login/mfa";
export const SIGNUP = "/signup";
export const SIGNUP_CHOICE = "/signup-choice";
export const SIGNUP_EMAIL = "/signup/email";
export const SIGNUP_EMAIL_CONFIRMATION = "/signup/email/confirmation";
export const SIGNUP_SOCIAL_NETWORK_CONFIRMATION =
  "/signup/social-network/confirmation";
export const PASSWORD_RECOVERY = "/password/recovery";
export const PASSWORD_RECOVERY_CONFIRMATION = "/password/recovery/confirmation";
export const PASSWORD_RESET = "/password/reset";
export const PASSWORD_RESET_SUCCESS = "/password/reset/success";
export const LOGOUT = "/login?logout=true";

// ZENDESK SUPPORT
export const SUPPORT = "https://support.mym.fans/hc/fr";
export const SUPPORT_FORM_CREATOR_ID = 8059301722908;

// PLATFORM STATUS
export const PLATFORM_STATUS = "https://status.mym.fans/";

// NPC
export const HOME = "/";
export const WELCOME = "/welcome";
export const PROFILE_CONFIGURATION_STEP_1 = "/profile/configuration/step-one";
export const PROFILE_CONFIGURATION_STEP_2 = "/profile/configuration/step-two";
export const PROFILE_CONFIGURATION_STEP_3 = "/profile/configuration/step-three";
export const PROFILE_CONFIGURATION_STEP_4 = "/profile/configuration/step-four";

// CERTIFICATION
export const CERTIFICATION_STEP_1 = "/account/certification/step-1";
export const CERTIFICATION_STEP_2 = "/account/certification/step-2";
export const CERTIFICATION_STEP_3 = "/account/certification/step-3";

// CONSULTATION
export const CONSULTATION = "/catalog/*";
export const CALENDAR = "calendar";
export const CONSULTATIONS_LIST = "consultations";
export const CONSULTATION_BY_ID = "consultations/:id";
export const CONSULTATION_CREATION = "consultations/creation";
export const CONSULTATION_CONFIGURATION_CREATION = "configuration/creation";
export const CONSULTATION_CALENDAR_SETUP = "configuration/calendar";
export const CONSULTATION_AVAILABILITY_SETUP = "configuration/availability";
export const CONSULTATION_EDIT = "consultations/edit/:id";
export const CONSULTATION_CONFIRMATION = "configuration/confirmation";
export const CONSULTATION_CALENDAR_CALLBACK = "calendar/oauthcallback";

export const BOOKINGS_LIST = "bookings";

// PROFILE
export const PROFILE = "/profile";
export const PROFILE_EDITION = "/profile/edit";
export const VIEW_POST = "/posts/:id";

// POST
export const POST = "/post/*";
export const POST_CONFIGURATION = "configuration";
export const POST_MYM_CLOUD = "mym-cloud";
export const POST_MYM_CLOUD_COLLECTIONS = "mym-cloud/collections";
export const POST_MYM_CLOUD_COLLECTION = "mym-cloud/collections/:id";
export const POST_CONFIRMATION = "confirmation";
export const POST_UPDATE = "/:id/edit";
export const POST_UPDATE_CONFIRMATION = "/:id/confirmation";

// PUSH
export const PUSH_CONFIGURATION = "/push/configuration";
export const PUSH_VISIBILITY = "/push/visibility";
export const PUSH_SCHEDULE = "/push/schedule";
export const PUSH_PRICE = "/push/price";
export const PUSH_RECAP = "/push/recap";
export const PUSH_CONFIRMATION = "/push/confirmation";

// LIVE
export const LIVE_CONFIGURATION = "/live/configuration";
export const LIVE_WARNING = "/live/warning";
export const CREATOR_LIVE_REDIRECT = (bookingId: string) =>
  `${environment.creatorUrl}/redirect/bookings/${bookingId}`;

// APP
export const TOOLS = "/tools";
export const STATISTICS = "/statistics";
export const NOTIFICATIONS = "/notifications";
export const MESSAGES = "/messages";

export const COOKIES = "/app/cookies";
export const ONLINE_HARASSMENT = "/app/online-harassment";
export const LEGAL_MENTIONS = "/app/legal-mentions";
export const CHILD_PROTECTION = "/app/child-protection";
export const ANTI_EXPLOITATION = "/app/anti-exploitation";
export const CONTENT_POLICY = "/app/content-policy";
export const TERMS_OF_SALE = "/app/terms-of-sale";
export const TERMS_OF_USE = "/app/terms-of-use";
export const PRIVACY = "/app/privacy";

export const CREATOR_CHAT = `${environment.creatorUrl}/chat`;
export const CREATOR_PROFILE = `${environment.creatorUrl}/profile`;

// PLAYLIST
export const PLAYLISTS = `/v2${TOOLS}/playlists`;
export const PLAYLIST = `/v2${TOOLS}/playlists/:id`;
