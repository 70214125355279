import {
  checkImageFormat,
  checkVideoFormat,
  isImage,
  isVideo
} from "@/utils/fileFormat";
import { getMediaResolution } from "@/utils/mediaResolution";
import * as yup from "yup";

import {
  MAXIMUM_IMAGE_SIZE,
  MAXIMUM_VIDEO_SIZE,
  MAX_DESCRIPTION_CHARACTERS,
  MIN_IMAGE_DIMENSION
} from "@/features/post/domain/constants";

export const uploadPost = yup.object({
  media: yup
    .mixed()
    .test("fileFormat", "mandatory_field_is_empty_error", (value) => {
      return value && value.properties.url && value.properties.type;
    }),
  caption: yup
    .string()
    .max(MAX_DESCRIPTION_CHARACTERS, "post_caption_input_limit_error"),
  isPrivate: yup.boolean().required(),
  isScheduled: yup.boolean().required(),
  scheduledAt: yup
    .date()
    .required()
    .when("date", {
      is: (value: Date) => value && value.getDate() === new Date().getDate(),
      then: yup.number().min(new Date().getHours(), "content_upload_error_date")
    }),
  playlists: yup.array().of(yup.string())
});

export const fileValidation = yup
  .mixed()
  .required()
  .test("imageSize", "post_image_file_size_error", (value) => {
    return isImage(value) ? value.size <= MAXIMUM_IMAGE_SIZE : true;
  })
  .test(
    "imageMinimumDimension",
    "post_media_dimensions_error",
    async (value) => {
      try {
        if (isVideo(value)) {
          return true;
        }

        const resolution = await getMediaResolution(
          value,
          MIN_IMAGE_DIMENSION,
          MIN_IMAGE_DIMENSION
        );

        return (
          resolution.width >= MIN_IMAGE_DIMENSION ||
          resolution.height >= MIN_IMAGE_DIMENSION
        );
      } catch (error) {
        return false;
      }
    }
  )
  .test("videoSize", "post_video_file_size_error", (value) => {
    return isVideo(value) ? value.size <= MAXIMUM_VIDEO_SIZE : true;
  })
  .test("imageFormat", "post_invalid_image_format_error", (value) => {
    return isImage(value) ? checkImageFormat(value) : true;
  })
  .test("fileVideoFormat", "post_invalid_video_format_error", (value) => {
    return isVideo(value) ? checkVideoFormat(value) : true;
  })
  .test("fileFormat", "post_invalid_format_error", (value) => {
    return isImage(value) || isVideo(value);
  })
  .required();

export type FileValidation = yup.InferType<typeof fileValidation>;
