import { useCallback } from "react";
import { useContext } from "react";

import { AuthContext, type AuthContextProps } from "@/contexts/auth/Auth";
import { environment } from "@/environments/environment";
import { isStandalone } from "@/utils/env";
import { useTranslation } from "react-i18next";

import { Button, Heading, Text, Title } from "@leeloo/core";

import { Content } from "@/components/content/Content";
import { Illustration } from "@/components/illustration/Illustration";

import * as styles from "./home.css";

export const Home = () => {
  const { t } = useTranslation();
  const { loading, logout } = useContext(AuthContext) as AuthContextProps;

  const redirect = useCallback(() => {
    return window.location.replace(`${environment.origin}/app/profile`);
  }, []);

  return (
    <Content>
      <Heading centered="mobile-and-desktop" className={styles.heading}>
        <Illustration name="warning" />
        <Title as="h3">{t("nothing_here_title")}</Title>
        <Text variant="large">{t("nothing_here_description")}</Text>
      </Heading>
      <Button
        children={isStandalone ? "Déconnexion" : t("nothing_here_button")}
        disabled={loading}
        onPress={() => {
          isStandalone ? logout() : redirect();
        }}
      />
    </Content>
  );
};

export default Home;
