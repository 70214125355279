import { SIGNUP } from "@/constants/routes";
import { useTranslation } from "react-i18next";

import { Text } from "@leeloo/core";

import { Link } from "../../../../../components/link/Link";

export const SignupLink = ({ ...props }) => {
  const { t } = useTranslation();

  return (
    <Text centered {...props}>
      {t("doesnt_have_an_account")}&nbsp;<Link to={SIGNUP}>{t("signup")}</Link>
    </Text>
  );
};
