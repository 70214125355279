import { useTranslation } from "react-i18next";

import { IconName } from "@leeloo/core";

import { Icon } from "@/components/icon/Icon";

import * as styles from "./footer.css";

export default function Footer() {
  const { t } = useTranslation();

  const footerLinks = [
    {
      name: t("footer_about"),
      href: "https://corporate.mym.fans/"
    },
    {
      name: t("footer_help"),
      href: "https://support.mym.fans/"
    },
    {
      name: t("footer_cookies"),
      href: `https://creators.mym.fans/app/cookies`
    },
    {
      name: t("footer_confidentiality"),
      href: `https://creators.mym.fans/app/privacy`
    },
    {
      name: t("terms_of_use_title"),
      href: `https://creators.mym.fans/app/terms-of-use`
    },
    {
      name: t("footer_cgs"),
      href: `https://creators.mym.fans/app/terms-of-sale`
    },

    {
      name: t("footer_cyber"),
      href: `https://creators.mym.fans/app/online-harassment`
    },

    {
      name: t("footer_incidents"),
      href: "https://status.mym.fans/"
    },
    {
      name: t("footer_legal"),
      href: `https://creators.mym.fans/app/legal-mentions`
    },
    {
      name: t("footer_child"),
      href: `https://creators.mym.fans/app/child-protection`
    },
    {
      name: t("footer_explotation"),
      href: `https://creators.mym.fans/app/anti-exploitation`
    },
    {
      name: t("footer_contentpolicy"),
      href: `https://creators.mym.fans/app/content-policy`
    }
  ];

  const socialLinks: { name: IconName; href: string }[] = [
    {
      name: "facebook",
      href: "https://www.facebook.com/mym.fans"
    },
    {
      name: "x",
      href: "https://twitter.com/mym_off"
    },
    {
      name: "instagram",
      href: "https://www.instagram.com/mym/"
    },
    {
      name: "document-pen",
      href: "https://blog.mym.com/"
    }
  ];

  return (
    <div className={styles.footer} data-testid="footer">
      <div className={styles.linksList}>
        {footerLinks.map((link, index) => (
          <a
            href={link.href}
            key={index}
            className={styles.link}
            data-testid={`sidebar-link-${link.name}`}
          >
            {link.name}
          </a>
        ))}
      </div>
      <div className={styles.socialsList}>
        {socialLinks.map((link, index) => (
          <a
            key={index}
            href={link.href}
            target="_blank"
            className={styles.social}
          >
            <Icon
              data-testid={`sidebar-social-link-${link.name}`}
              name={link.name}
              size="large"
            />
          </a>
        ))}
      </div>
    </div>
  );
}
