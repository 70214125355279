export const loginLinkRoutes = [
  "/app/signup-choice",
  "/app/signup/email",
  "/app/password/reset"
];

export const signupLinkRoutes = [
  "/app/login",
  "/app/login/mfa",
  "/app/password/recovery",
  "/password/recovery/confirmation"
];
