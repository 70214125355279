import React from "react";

import { Provider as AnalyticsContextProvider } from "@/contexts/analytics/Analytics";
import { Provider as AuthContextProvider } from "@/contexts/auth/Auth";
import { Provider as ErrorContextProvider } from "@/contexts/error/Error";
import { Provider as UserContextProvider } from "@/contexts/user/User";
import { ModalProvider } from "react-modal-hook";
import { BrowserRouter, Route } from "react-router-dom";

import { LayoutAuth } from "@/components/layout-auth/LayoutAuth";
import { LayoutUnauth } from "@/components/layout-unauth/LayoutUnauth";

import { SentryRoutes } from "@/services/sentry";

import { routes as authRoutes } from "./auth/Auth";
import { routes as consultationRoutes } from "./consultation/Consultation";
import { routes as playlistRoutes } from "./playlist/Playlist";
import { routes as postRoutes } from "./post/Post";
import { routes as profileRoutes } from "./profile/ProfileRoutes";
import { routes as pushRoutes } from "./push/Push";

const Oauth2Callback = React.lazy(
  () =>
    import(
      "@/features/authentication/presentation/pages/oauth2-callback/Oauth2Callback"
    )
);

const routes = [
  ...authRoutes,
  ...consultationRoutes,
  ...profileRoutes,
  ...pushRoutes,
  ...postRoutes,
  ...playlistRoutes
];

export interface RouteConfig {
  path: string;
  element: JSX.Element;
  isPrivate?: boolean;
  isPublic?: boolean;
}

export const Router = () => {
  return (
    <BrowserRouter basename="/app">
      <ErrorContextProvider>
        <AnalyticsContextProvider>
          <SentryRoutes>
            {routes.map((route, index) => (
              <Route
                element={
                  route.isPrivate ? (
                    <LayoutAuth>
                      <AuthContextProvider secure={true}>
                        <UserContextProvider>
                          <ModalProvider>{route.element}</ModalProvider>
                        </UserContextProvider>
                      </AuthContextProvider>
                    </LayoutAuth>
                  ) : (
                    <LayoutUnauth>
                      <AuthContextProvider secure={false}>
                        <UserContextProvider>
                          <ModalProvider>{route.element}</ModalProvider>
                        </UserContextProvider>
                      </AuthContextProvider>
                    </LayoutUnauth>
                  )
                }
                key={route.path}
                path={route.path}
              />
            ))}
            <Route element={<Oauth2Callback />} path="/oauth2/callback" />
          </SentryRoutes>
        </AnalyticsContextProvider>
      </ErrorContextProvider>
    </BrowserRouter>
  );
};
