import { Aside, Heading, IllustrationName, Text, Title } from "@leeloo/core";

import Footer from "@/components/footer/Footer";
import { Illustration } from "@/components/illustration/Illustration";

import * as styles from "./sidebar.css";

export interface Sidebar {
  illustration?: string | React.ReactNode;
  title?: string | React.ReactNode;
  description?: string | React.ReactNode;
  informations?: string | React.ReactNode;
  progression?: React.ReactNode;
  logged?: boolean;
  withFooter?: boolean;
}

export const Sidebar = ({
  illustration = "creators",
  title,
  description,
  informations,
  progression,
  logged,
  withFooter,
  ...props
}: Sidebar) => {
  return (
    <aside {...props} className={styles.aside({ logged })}>
      {illustration || title || description || informations || progression ? (
        <Heading centered="mobile-and-desktop">
          {!!illustration && (
            <Illustration name={illustration as IllustrationName} />
          )}
          {title && <Title as="h3">{title}</Title>}
          {description && (
            <Text className={styles.description}>{description}</Text>
          )}
          {informations && <Text>{informations}</Text>}
          {progression && progression}
        </Heading>
      ) : null}
      {withFooter ? <Footer /> : null}
    </aside>
  );
};
