import clsx from "clsx";

import { Text, Title } from "@leeloo/core";

import { Illustration } from "@/components/illustration/Illustration";

import * as styles from "./empty-state.css";

export interface EmptyStateProps {
  className?: string;
  translations: {
    title?: string;
    description?: string;
  };
  children?: React.ReactNode;
}

const EmptyState = ({
  className,
  translations,
  children,
  ...props
}: EmptyStateProps) => {
  return (
    <div
      className={clsx(className, styles.emptyState)}
      data-testid="empty-state"
      {...props}
    >
      <Illustration name="empty-state" data-testid="empty-state-illustration" />
      <div className={styles.emptyStateTitle}>
        <Title as="h3" data-testid="empty-state-title">
          {translations.title}
        </Title>
        <Text data-testid="empty-state-text">{translations.description}</Text>
      </div>
      {children}
    </div>
  );
};
export default EmptyState;
