import React from "react";

import { POST, POST_MYM_CLOUD_COLLECTION } from "@/constants/routes";
import { RouteConfig } from "@/routes/routes";

import Collection from "@/features/post/presentation/pages/collection/Collection";
import PostRoutes from "@/features/post/presentation/routes/PostRoutes";

export const routes: RouteConfig[] = [
  {
    path: POST,
    element: <PostRoutes />,
    isPrivate: true
  },
  {
    path: POST_MYM_CLOUD_COLLECTION,
    isPrivate: true,
    element: <Collection />
  }
];
