import { collectionsKey } from "@/constants/queryKeys";
import { useInfiniteQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useParams } from "react-router-dom";

import { MEDIAS_PER_PAGE } from "@/features/post/domain/constants";
import collectionUseCases from "@/features/post/domain/use-cases/collection";
import {
  GetCollectionByIdResponse,
  collectionDataSourceImplementation
} from "@/features/post/infrastructure/datasources/collection/collectionDataSourceImplementation";
import httpImplementation from "@/features/post/infrastructure/services/httpImplementation";

export const useCollectionByIdInfiniteQuery = () => {
  const httpClient = httpImplementation();
  const collectionDataSourceImpl =
    collectionDataSourceImplementation(httpClient);
  const collectionsUC = collectionUseCases(collectionDataSourceImpl);
  const { id } = useParams();

  return useInfiniteQuery({
    queryKey: [collectionsKey, id],
    initialPageParam: "",
    queryFn: ({ pageParam }) =>
      collectionsUC.getCollectionById<AxiosResponse<GetCollectionByIdResponse>>(
        {
          id: id || "",
          pagination: {
            perPage: MEDIAS_PER_PAGE,
            offset: pageParam
          }
        }
      ),
    getNextPageParam: (lastPage) =>
      lastPage.data.pagination.offset || undefined,
    select: (
      data
    ): {
      name: string;
      medias: GetCollectionByIdResponse["medias"];
      itemsTotal: number;
    } => {
      const name = data.pages[0]?.data.name || "";
      const medias = data.pages.flatMap((page) => {
        return page.data.medias && page.data.medias.length > 0
          ? page.data.medias
          : [];
      });

      const itemsTotal = data.pages[0]?.data.pagination.itemsTotal || 0;

      return { name, medias, itemsTotal };
    }
  });
};
