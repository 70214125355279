import { lazy } from "react";

import {
  BOOKINGS_LIST,
  CALENDAR,
  CONSULTATIONS_LIST,
  CONSULTATION_AVAILABILITY_SETUP,
  CONSULTATION_BY_ID,
  CONSULTATION_CALENDAR_CALLBACK,
  CONSULTATION_CALENDAR_SETUP,
  CONSULTATION_CONFIGURATION_CREATION,
  CONSULTATION_CREATION,
  CONSULTATION_EDIT
} from "@/constants/routes";
import { CONSULTATION_CONFIRMATION } from "@/constants/routes";
import { CreatorContext } from "@/contexts/creator/Creator";
import { Outlet } from "react-router-dom";
import { Navigate, Route, Routes } from "react-router-dom";

import { Error } from "@/features/consultation/presentation/components/error/Error";
import {
  useCalendarProvidersQuery,
  useCreatorMeQuery
} from "@/features/consultation/presentation/routes/viewModel";

const AvailabilitySetup = lazy(
  () => import("@/features/consultation/presentation/pages/AvailabilitySetup")
);
const Bookings = lazy(
  () => import("@/features/consultation/presentation/pages/Bookings")
);
const Calendar = lazy(
  () => import("@/features/consultation/presentation/pages/Calendar")
);
const CalendarSetup = lazy(
  () => import("@/features/consultation/presentation/pages/CalendarSetup")
);
const Confirmation = lazy(
  () => import("@/features/consultation/presentation/pages/Confirmation")
);
const Consultation = lazy(
  () => import("@/features/consultation/presentation/pages/Consultation")
);
const ConsultationsList = lazy(
  () => import("@/features/consultation/presentation/pages/ConsultationsList")
);
const OAuhCallback = lazy(
  () => import("@/features/consultation/presentation/pages/OAuthCallback")
);
const Setup = lazy(
  () => import("@/features/consultation/presentation/pages/Setup")
);
const Update = lazy(
  () => import("@/features/consultation/presentation/pages/Update")
);

export const ConsultationRoutes = () => {
  const ConsultationCreation = () => {
    const { data } = useCalendarProvidersQuery();
    return data ? (
      <Setup />
    ) : (
      <Navigate to={`/catalog/${CONSULTATION_CALENDAR_SETUP}`} replace={true} />
    );
  };

  const { data: creator, isError, refetch } = useCreatorMeQuery();

  const renderPage = (component: JSX.Element) => {
    return isError ? <Error onClick={() => refetch()} /> : component;
  };

  const routeConfig = [
    {
      path: CONSULTATIONS_LIST,
      element: renderPage(<ConsultationsList />)
    },
    { path: CALENDAR, element: renderPage(<Calendar />) },
    {
      path: CONSULTATION_CALENDAR_SETUP,
      element: renderPage(<CalendarSetup />)
    },
    {
      path: CONSULTATION_AVAILABILITY_SETUP,
      element: renderPage(<AvailabilitySetup />)
    },
    {
      path: CONSULTATION_CONFIGURATION_CREATION,
      element: renderPage(<ConsultationCreation />)
    },
    {
      path: CONSULTATION_CREATION,
      element: renderPage(<ConsultationCreation />)
    },
    { path: CONSULTATION_EDIT, element: renderPage(<Update />) },
    { path: CONSULTATION_BY_ID, element: renderPage(<Consultation />) },
    {
      path: CONSULTATION_CONFIRMATION,
      element: renderPage(<Confirmation />)
    },
    { path: BOOKINGS_LIST, element: renderPage(<Bookings />) },
    { path: CONSULTATION_CALENDAR_CALLBACK, element: <OAuhCallback /> }
  ];

  return (
    <CreatorContext category={creator?.category || ""}>
      <Routes>
        <Route path="/" element={<Outlet />}>
          {routeConfig.map((route, index) => {
            return (
              <Route key={index} path={route.path} element={route.element} />
            );
          })}
        </Route>
      </Routes>
    </CreatorContext>
  );
};
