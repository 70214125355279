import React, { useCallback, useEffect, useState } from "react";

import { CREATOR_PROFILE, LOGOUT, PROFILE } from "@/constants/routes";
import { environment } from "@/environments/environment";
import { isStandalone } from "@/utils/env";
import { Auth, Hub } from "aws-amplify";
import { AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { showToast } from "@leeloo/core";

import creatorUseCases from "@/features/authentication/domain/use-cases/creator";
import {
  CreatorDTO,
  creatorDataSourceImplementation
} from "@/features/authentication/infrastructure/datasources/creator/creatorDataSourceImplementation";
import httpImplementation from "@/features/authentication/infrastructure/services/httpImplementation";
import { trackingImplementation } from "@/features/authentication/infrastructure/services/trackingImplementation";
import { useAuthStore } from "@/features/authentication/presentation/pages/login/viewModel";

const AuthContext = React.createContext({});

export { AuthContext };

export interface AuthContextProps {
  isLogout?: boolean;
  loading: boolean;
  logged: boolean;
  logout: () => void;
}

export const Provider = ({ secure = false, ...props }) => {
  const { logged, updateCognitoUser, updateLogged, updateSession } =
    useAuthStore();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isLogout = searchParams.get("logout") || false;
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const { pathname } = useLocation();

  useEffect(() => {
    const canControlScrollRestoration = "scrollRestoration" in window.history;

    if (canControlScrollRestoration) {
      window.history.scrollRestoration = "manual";
    }

    window.scrollTo(0, 0);
  }, [pathname]);

  const logout = useCallback(async function () {
    if (!isStandalone) {
      window.location.replace(`${environment.origin}/app/sign-out`);
      return;
    }

    try {
      updateCognitoUser(undefined);
      updateLogged(false);
      updateSession(undefined);
      setLoading(true);

      await Auth.signOut();
      navigate(LOGOUT, { replace: true });
    } catch (error) {
      showToast({
        description: t("technical_error_toast"),
        title: t("technical_error_toast_title"),
        variant: "error"
      });
    } finally {
      setLoading(false);
    }
  }, []);

  const refreshSession = useCallback(
    async ({ autoSignInFailure = false }: { autoSignInFailure?: boolean }) => {
      try {
        const session = await Auth.currentSession();

        updateLogged(true);
        updateSession(session);
      } catch (error) {
        updateCognitoUser(undefined);
        updateLogged(false);
        updateSession(undefined);

        if (secure || autoSignInFailure) {
          navigate(LOGOUT, { replace: true });
        }
      } finally {
        setLoading(false);
      }
    },
    [secure]
  );

  useEffect(() => {
    (async function () {
      await refreshSession({});
    })();
  }, []);

  useEffect(() => {
    const unsubscribe = Hub.listen("auth", ({ payload: { event } }) => {
      (async function () {
        switch (event) {
          case "signIn": {
            const userDataSource =
              creatorDataSourceImplementation(httpImplementation);
            const userUseCase = creatorUseCases(userDataSource);
            const trackImpl = trackingImplementation;

            /*
            @TODO use react query in order to avoid getting profile creator twice
             */
            const { data: user } = await userUseCase.getMe<
              AxiosResponse<CreatorDTO>
            >();

            user.id && trackImpl.identify(user.id);

            if (isStandalone) {
              navigate(PROFILE);
            } else {
              window.location.assign(CREATOR_PROFILE);
            }

            break;
          }
          case "autoSignIn": {
            await Auth.currentSession();
            const userDataSource =
              creatorDataSourceImplementation(httpImplementation);
            const userUseCase = creatorUseCases(userDataSource);
            const trackImpl = trackingImplementation;

            const { data: user } = await userUseCase.getMe<
              AxiosResponse<CreatorDTO>
            >();

            user.id && trackImpl.identify(user.id);

            await refreshSession({});
            break;
          }
          case "autoSignIn_failure":
            if (event === "autoSignIn_failure") {
              showToast({
                description: t("signup_confirmed_toast"),
                title: t("signup_confirmed_toast_title"),
                variant: "success"
              });
            }

            await refreshSession({
              autoSignInFailure: event === "autoSignIn_failure"
            });
            break;
          case "signOut":
            await refreshSession({});
            break;
        }
      })();
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    if (secure && !logged) {
      navigate(LOGOUT, { replace: true });
      return;
    }

    if (!secure && logged && !isLogout) {
      if (isStandalone) {
        navigate(PROFILE);
      } else {
        window.location.assign(CREATOR_PROFILE);
      }

      return;
    }

    if (!secure && logged && isLogout) {
      logout();
      return;
    }
  }, [location.key]);

  return (
    <AuthContext.Provider
      {...props}
      value={{ isLogout, loading, logged, logout }}
    />
  );
};
